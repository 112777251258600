import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  scrollSpy1: 'Intro',
  scrollSpy2: 'Benefits',
  scrollSpy3: 'FAQ',
  scrollSpy4: 'Process',
  scrollSpy5: 'Packages',
  scrollSpy6: 'Team',
  scrollSpy7: 'Contact',
  heroTitle: 'Professional <b>UX/UI Audits</b> for digital success',
  heroText: 'Recognise untapped potential and turn visitors into satisfied customers. Our UX/UI audit provides clear recommendations to improve your digital platform in a targeted manner.',
  heroExplore: 'Explore packages',
  heroDiscover: 'Discover the process',
  customAnalysisTitle: 'Customised UX and UI analysis - improve the user experience',
  customAnalysisText: '<p>A UX/UI audit allows you to look at your website or digital platform from a new perspective. We analyse the user guidance, design and user-friendliness in detail in order to uncover weaknesses and provide targeted recommendations for action.</p><p>With our many years of experience in e-commerce, our analysis delivers customised optimisations that make your digital sales platform more effective and appealing.</p>',
  customAnalysisBtn: 'Get free consultation',
  optimizeSectionTitle: 'Optimize user experience and user interface - increase leads, conversions & user loyalty',
  optimizeSectionGuidanceTitle: 'Optimized user guidance',
  optimizeSectionGuidanceText: 'Optimize the user flow for an intuitive user experience and higher satisfaction.',
  optimizeSectionConversionTitle: 'Conversion increase',
  optimizeSectionConversionText: 'Identify weak points in the conversion funnel and use best practices as a blueprint.',
  optimizeSectionLoyaltyTitle: 'Stronger user loyalty',
  optimizeSectionLoyaltyText: 'Create a consistent experience to drive retention, revisit rates and referrals.',
  optimizeSectionAnalysisTitle: 'In-depth user analysis',
  optimizeSectionAnalysisText: 'Detailed analyses show user behaviour and optimization potential for your digital platform.',
  optimizeSectionActingTitle: 'Acting purposefully',
  optimizeSectionActingText: 'Implement our tips to eliminate weaknesses and improve your website.',
  optimizeSectionIndustryTitle: 'Industry relevance',
  optimizeSectionIndustryText: 'Use our e-commerce experience to optimize your digital sales in line with your target group.',
  faqSectionTitle: 'FAQs on UX/UI audits for optimal user experience',
  faqSectionText: 'Here you will find answers to frequently asked questions about analytics and how we can help you improve your digital presence.',
  faqSectionQ1: 'What aspects are evaluated in a UX/UI audit?',
  faqSectionA1: 'A UX/UI audit evaluates various aspects of your website, including usability, navigation, user flows, visual design, loading times and responsiveness. It checks how intuitive and appealing the user interface is and whether the platform meets the expectations and needs of the target group. All criteria are checked on the basis of industry-specific best practices and conversion-optimized user flows.',
  faqSectionQ2: 'Can a UX/UI audit also be done for mobile apps or other digital products?',
  faqSectionA2: 'Yes, a UX/UI audit can also be carried out for mobile apps and other digital products. The methodology remains the same, but the focus is adapted to the specific requirements and user interactions of the respective platform in order to achieve optimal results and formulate targeted recommendations.',
  faqSectionQ3: 'How often should a UX/UI audit be done?',
  faqSectionA3: 'We recommend conducting a UX/UI audit approximately every two years, especially after major changes to the platform or the launch of new features. Regular audits help to continuously improve the user experience and ensure that your digital product remains up-to-date and effective.',
  faqSectionQ4: 'How are the results of the audit presented and what do the recommendations contain?',
  faqSectionA4: 'The results are summarized in a detailed report that contains clear analyses and recommendations for action. This report describes weaknesses, provides suggestions for improvement and formulates priorities that help you to make targeted optimizations and improve the user experience and conversions of your products.',
  faqSectionQ5: 'Can I implement the results and recommendations of the UX/UI audit myself or do I need support?',
  faqSectionA5: 'If you have the necessary know-how and resources in your own development departments or with implementation service providers, you can implement our recommendations independently. If you are interested in a general collaboration, we can also show you a plan for this.',
  moreInfoSectionTitle: 'More information on the topic of UX/UI optimization',
  moreInfoSectionText: 'If you require additional information or would like an individual consultation, we look forward to hearing from you. Arrange a free initial consultation now and find out how a user experience and user interface check can contribute to the success of your online presence.',
  moreInfoSectionBtn: 'Get free consultation',
  stepsSectionTitle: 'Three steps to a better user experience',
  stepsSectionCallPreTitle: '01',
  stepsSectionCallTitle: 'Initial call',
  stepsSectionCallText: 'In the initial meeting for a UX/UI audit, your project goals, user groups and existing design problems are discussed. It serves to understand the challenges, evaluate the current user experience and define the scope of the audit to improve usability and design.',
  stepsSectionAuditPreTitle: '02',
  stepsSectionAuditTitle: 'Detailed UX/UI audit',
  stepsSectionAuditText: 'Our detailed UX/UI audit includes the analysis of user guidance, visual design, user feedback, accessibility, usability tests, competitive analysis and general performance. The aim is to identify weaknesses and determine potential improvements for an optimal user experience.',
  stepsSectionImplPreTitle: '03',
  stepsSectionImplTitle: 'Documentation & implementation',
  stepsSectionImplText: 'All findings and suggestions for optimization are summarized in detailed documentation. This contains clear recommendations for action that you can implement directly. This gives you a precise plan to improve the user experience in the long term and achieve your goals.',
  plansSectionTitle: 'The right level of detail based on your needs',
  plansSectionBadge: 'Recommended',
  plansSectionBtn: 'Request a quote now',
  plansSectionBasicTitle: 'Basic UX/UI Check',
  plansSectionBasicText: 'A basic check of your digital platform that gives you an overview of the most important optimization potential.',
  plansSectionBasicBenefit1: 'Review of user guidance & flow',
  plansSectionBasicBenefit2: 'Analysis of the main navigation & landing pages',
  plansSectionBasicBenefit3: 'Identification of usability problems',
  plansSectionBasicBenefit4: 'Basic performance evaluation',
  plansSectionBasicBenefit5: 'Documentation of the results',
  plansSectionBasicBenefit6: 'Recommendations for optimization',
  plansSectionInsightTitle: 'Insight Report',
  plansSectionInsightText: 'Additional analyses of the target group give us a more detailed overall picture and enable us to make even more targeted recommendations.',
  plansSectionInsightPreBenefits: 'All services from the Basic UX/UI Check',
  plansSectionInsightBenefit1: 'Advanced analysis of user interactions',
  plansSectionInsightBenefit2: 'Evaluation of conversion potential',
  plansSectionInsightBenefit3: 'Detailed performance analysis',
  plansSectionInsightBenefit4: 'Best practice analysis & comparison',
  plansSectionInsightBenefit5: 'Competitor comparison',
  plansSectionInsightBenefit6: 'Prioritized recommendations for action',
  plansSectionInsightBenefit7: 'Roadmap with all recommendations for action',
  plansSectionUltimateTitle: 'Ultimate Experience Audit',
  plansSectionUltimateText: 'A comprehensive audit that analyzes your digital presence in depth. Ideal for companies that need tailored optimization.',
  plansSectionUltimatePreBenefits: 'All services from the Insight Report',
  plansSectionUltimateBenefit1: 'Complete analysis of all touchpoints',
  plansSectionUltimateBenefit2: 'Extensive usability tests',
  plansSectionUltimateBenefit3: 'Individual strategy recommendations',
  plansSectionUltimateBenefit4: 'Comprehensive conversion optimization',
  plansSectionUltimateBenefit5: 'Target group-specific design reviews',
  plansSectionUltimateBenefit6: 'Long-term implementation consulting',
  plansSectionUltimateBenefit7: 'Customized workshops and training courses',
  expertsSectionTitle: 'Our experts for your UX/UI audit',
  expertsSectionDominicText: 'Expert in travel e-commerce, B2B software and process optimization for more than 15 years.',
  expertsSectionTobiasText: 'Senior UX/UI Designer, specialized in the optimization of landing pages and e-commerce platforms.',
  expertsSectionPatrickText: 'Over 15 years of experience in B2C & B2B e-commerce with in-depth knowledge of different industries.',
  expertsSectionDominikText: 'Senior UX/UI Engineer with a strong focus on branding and scalable design systems.',
  consultationSectionTitle: 'Get your free initial consultation',
  consultationSectionText: 'Take the opportunity to improve your digital presence. You will receive valuable insights and individual recommendations in a free initial consultation. Contact us today using the form and secure your consultation.',
  formTitle: 'Get <b>free advice</b> on <b>UX/UI audits</b> now',
  formButtonLabel: 'Arrange a free initial consultation',
  quoteText: 'The UX/UI audit by freshcells provided us with valuable insights that enabled us to further optimise the user experience. Highly recommended!'
})
